var h = function h(str) {
  var hash = 5381,
      i = str.length;

  while (i) {
    hash = hash * 33 ^ str.charCodeAt(--i);
  }

  return (hash >>> 0) + 2147483647 + 1;
};

var createHash = function createHash(str, timestamp) {
  var ts = timestamp || Date.now();
  return h('' + str + ts) + "-" + ts;
};

var verifyHash = function verifyHash(str, hash) {
  var ts = hash.split('-')[1];
  return createHash(str, ts) === hash;
};

var getUniquePostPayloadString = function getUniquePostPayloadString(payload) {
  return "" + payload.categoryId + payload.title + (payload.content ? payload.content.blocks.map(function (b) {
    return b.key;
  }).join('') : '');
};

var tokenName = 'h' + 's' + 'T' + 'o' + 'k' + 'e' + 'n';
var s = '450s8i19';
module.exports = {
  createHash: createHash,
  verifyHash: verifyHash,
  getUniquePostPayloadString: getUniquePostPayloadString,
  tokenName: tokenName,
  s: s
};